<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'logistics-officer.pending-requests' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.pending }}</h2>
                  <p class="fontsize-sm m-0 text-warning">
                    Pending Request(s)
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-warning">
                  <i class="ri-inbox-fill" />
                </div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'logistics-officer.approved-requests' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.approved }}</h2>
                  <p class="fontsize-sm m-0 text-success">
                    Approved Request(s)
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                  <i class="ri-inbox-fill" />
                </div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'logistics-officer.vehicle-request-histories', params: {status: 'Completed'}}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.completed }}</h2>
                  <p class="fontsize-sm m-0 text-info">
                    Completed Request(s)
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-info">
                  <i class="ri-inbox-fill" />
                </div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { LogisticsOfficerDashboardService } from '@/services'

export default {
  name: 'LogisticsOfficerDashboard',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      requests: {
        pending: 0,
        approved: 0,
        completed: 0
      }
    }
  },

  mounted () {
    core.index()
    this.fetchStatistics()
  },

  methods: {
    async fetchStatistics () {
      await LogisticsOfficerDashboardService.getStatistics().then(({ data }) => {
        this.requests = data
      })
    }
  }
}
</script>
